import React,{useEffect} from 'react'
import ErrorImg from "../../assets/styles/images/error_img.png"

function ErrorFallback({ error, resetErrorBoundary }) {

   useEffect(()=>{
    console.log("inside error fallback component",error?.message)
    if(!localStorage.getItem("isError")){
      localStorage.setItem("isError",true)
      window.location.reload()
    }else{
      localStorage.removeItem("isError")
    }

   },[])
  return (<>{navigator.onLine?(
    <div className="error-fallback" >
    <img src={ErrorImg} alt="page Not Found"/>
    </div>
  ):(<div className='offline-wrap'>

    <div>
    <div className="headingone">インターネット接続がオフラインです。</div>
       <span className="subhead">申し訳ありませんが、現在インターネットに接続されていないようです。
再度接続を確認してください。</span>
    </div>
  
  </div>)}
  
  </>
    
  )
}

export default ErrorFallback