import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  workshopDetails: {},
  workshopParticipantList: {},
  workshopInventoriesList: {},
  workshopFollowUpsList: {},
  responseList: {},
  taskSetting:{},
  errors: {},
};

export const slice = createSlice({
  name: "workshopDetails",
  initialState,
  reducers: {
    clearworkshopDetailsData: () => initialState,
    setworkshopDetailsLoading: (state, action) => {
      state.isLoading = true;
    },
    setworkshopParticipantData: (state, action) => {
      state.isLoading = false;
      state.workshopParticipantList = action.payload;
    },
    setworkshopDetailsData: (state, action) => {
      state.isLoading = false;
      state.workshopDetails = action.payload;
    },
    setworkshopInventoriesData: (state, action) => {
      state.isLoading = false;
      state.workshopInventoriesList = action.payload;
    },
    setworkshopFollowUpsData: (state, action) => {
      state.isLoading = false;
      state.workshopFollowUpsList = action.payload;
    },
    setworkshopDetailsError: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload;
    },
    setresponseListData: (state, action) => {
      state.isLoading = false;
      state.responseList = action.payload;
    },
    settaskSettingData: (state, action) => {
      state.isLoading = false;
      state.taskSetting = action.payload;
    },
    clearworkshopDetialsError: (state) => {
      state.isLoading = false;
      state.errors = {};
    },
  },
});

export const {
  clearworkshopDetailsData,
  setworkshopDetailsLoading,
  setworkshopParticipantData,
  setworkshopDetailsData,
  setworkshopInventoriesData,
  setworkshopFollowUpsData,
  setworkshopDetailsError,
  clearworkshopDetialsError,
  setresponseListData,
  settaskSettingData
} = slice.actions;

export default slice.reducer;
