import { toast } from "react-toastify";
import { clearloginData } from "../../src/redux/slice/loginSlice";
import { clearcompaniesData } from "../../src/redux/slice/companySlice";
import { clearworkshopsData } from "../../src/redux/slice/workshopSlice";
import { clearParticipantDetailsData } from "../../src/redux/slice/participantDetailsSlice";
import { clearParticipantData } from "../..//src/redux/slice/participantSlice";
import { clearRegisterData } from "../..//src/redux/slice/registerSlice";
import { clearWorkshopAdminData } from "../..//src/redux/slice/workshopAdminSlice";
import { clearworkshopDetailsData } from "../..//src/redux/slice/workshopDetailSlice";
import {clearParticipantDashboardData} from "../..//src/redux/slice/participantDashboardSlice"
import moment from "moment-timezone";

const from_Invalid_status_array = "details";
export const showError = (response, t) => {
  if (response?.errors || response?.error) {
    if (response?.error?.error_code) {
      toast.error(t(response?.error?.error_code), { toastId: 1 });
    } else if (
      response?.errors?.error?.hasOwnProperty(from_Invalid_status_array)
    ) {
    }
  }
};

export const clearReducers=(dispatch)=>{
  dispatch(clearloginData())
  dispatch(clearcompaniesData())
  dispatch(clearworkshopsData())
  dispatch(clearParticipantDetailsData())
  dispatch(clearParticipantData())
  dispatch(clearRegisterData())
  dispatch(clearWorkshopAdminData())
  dispatch(clearworkshopDetailsData())
  dispatch(clearParticipantDashboardData())
}
export function extractDate(inputString) {
  const datePart = inputString?.split(" ")[0] || 0;
  return datePart;
}

export function extractDateAndTime(inputString) {
  const dateTimePart =
    (inputString?.split(" ")[0] || 0) +
    " " +
    (inputString?.split(" ")[1] ? inputString?.split(" ")[1]?.slice(0, 5) : "");
  return dateTimePart;
}

export function convertUTCToJST(utcDatetimeStr) {
  if (utcDatetimeStr) {
    const utcDatetime = moment.utc(utcDatetimeStr, "YYYY-MM-DD HH:mm:ss Z");
    const jstDatetime = utcDatetime.clone().tz("Asia/Tokyo");
    const jstFormattedString = jstDatetime.format("YYYY-MM-DD HH:mm:ss");
    return jstFormattedString;
  }
}

export function convertJSTtoUTC(jstDateTimeString) {
  if (jstDateTimeString) {
    // Assuming jstDateTimeString is in the format 'YYYY-MM-DD HH:mm:ss'
    const jstDateTime = moment.tz(jstDateTimeString, "Asia/Tokyo");

    const utcDateTime = jstDateTime.clone().utc();
    // Format the result in UTC
    const utcFormattedString = utcDateTime.format("YYYY-MM-DD HH:mm:ss");
    return utcFormattedString;
  }
}

export function FormattedDate(dateString) {
  // Parse the input date string using moment
  const parsedDate = moment(dateString);

  // Format the date to 'YYYY-MM-DD'
  const formattedDate = parsedDate.format("YYYY-MM-DD");

  return formattedDate;
}

export function isDateGreater(dateString1) {
  const currentDate = moment.utc();
  const jstDateTime = moment.tz(dateString1, "Asia/Tokyo");
  const inputDate = jstDateTime.clone().utc();
  return inputDate.isAfter(currentDate);
}

export function optionValueSet(value, key, options) {
  return options?.find((data) => data?.[key] === value);
}
