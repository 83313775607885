import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  loginData: {},
  errors:{},
  company:'',
  workshop:{},
};

export const slice = createSlice({
  name: "login",
  initialState,
  reducers: {
    clearloginData: () => initialState,
    setloginLoading:(state, action) => {
      state.isLoading = true;
    },
    setloginData: (state, action) => {
      state.isLoading = false;
      state.loginData = action.payload;
    },
    setloginError: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload;
    },
    clearloginError: (state) => {
      state.isLoading = false;
      state.errors = {};
    },
    setToken: (state, action) => {
      state.isLoading = false;
      state.loginData.access_token = action.payload?.access_token;
      state.loginData.expires_in = action.payload?.expires_in;
      state.loginData.token_type = action.payload?.token_type;
    },
    setCompany: (state, action) => {
      state.isLoading = false;
      state.company = action.payload;
     
    },
    setWorkshop: (state, action) => {
      state.isLoading = false;
      state.workshop = action.payload;
     
    },
  },
});

export const { clearloginData, setloginLoading,setloginData,setloginError,clearloginError,setToken,setCompany,setWorkshop} =slice.actions;

export default slice.reducer;
