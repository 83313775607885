import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  companiesData: {},
  errors:{}
};

export const slice = createSlice({
  name: "company",
  initialState,
  reducers: {
    clearcompaniesData: () => initialState,
    setcompaniesLoading:(state, action) => {
      state.isLoading = true;
    },
    setcompaniesData: (state, action) => {
      state.isLoading = false;
      state.companiesData = action.payload;
    },
    setcompaniesError: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload;
    },
    clearcompaniesError: (state) => {
      state.isLoading = false;
      state.errors = {};
    },
  },
});

export const { clearcompaniesData, setcompaniesLoading,setcompaniesData,setcompaniesError,clearcompaniesError} =slice.actions;

export default slice.reducer;
