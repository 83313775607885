import React, { useState, useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import ParticipantFooter from "../components/common/participantFooter";

function ParticipantDetailsLayout() {
    const [currentPath, setCurrentPath] = useState(window?.location?.pathname);
    const isLogin = localStorage.getItem("participant_token") || null;

    useEffect(() => {
        const handleLocationChange = () => {
            setCurrentPath(window?.location?.pathname);
        };
        window.addEventListener("popstate", handleLocationChange);
        return () => {
            window.removeEventListener("popstate", handleLocationChange);
        };
    }, []);

    return (
        <>
            {isLogin ? (
                <div className="main_bg d-flex flex-column min-vh-100">
                    <section>
                        <Outlet />
                        <ParticipantFooter />
                    </section>
                </div>
            ) : (
                <Navigate to={`/participants/login?redirect=${encodeURIComponent(currentPath)}`} replace />
            )}
        </>
    );
}

export default ParticipantDetailsLayout;
