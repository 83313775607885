import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  participantDashboard: {},
  errors: [],
};

export const slice = createSlice({
  name: "participantDashboard",
  initialState,
  reducers: {
    clearParticipantDashboardData: () => initialState,
    setParticipantDashboardLoading: (state, action) => {
      state.isLoading = true;
    },
    setParticipantDashboardData: (state, action) => {
      state.isLoading = false;
      state.participantDashboard = action.payload;
    },
    setParticipantDashboardError: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload;
    },
    clearParticipantDashboardError: (state) => {
      state.isLoading = false;
      state.errors = [];
    },
  },
});

export const {
  clearParticipantDashboardData,
  setParticipantDashboardLoading,
  setParticipantDashboardData,
  setParticipantDashboardError,
  clearParticipantDashboardError,
} = slice.actions;

export default slice.reducer;
