import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  dashboardDetailsInventory: {},
  dashboardDetailsInventoryResponse: {},
  dashboardDetailsEvaluation: {},
  dashboardDetailsEvaluationconfirm:{},
  dashboardDetailsEvaluationResponse: {},
  dashboardDetailsFollowup: {},
  dashboardDetailsFollowupResponse: {},
  errors: [],
};

export const slice = createSlice({
  name: "dashboardDetails",
  initialState,
  reducers: {
    clearDashboardDetailsData: () => initialState,
    setDashboardDetailsLoading: (state, action) => {
      state.isLoading = true;
    },
    setDashboardDetailsInventoryData: (state, action) => {
      state.isLoading = false;
      state.dashboardDetailsInventory = action.payload;
    },
    setDashboardDetailsInventoryResponseData: (state, action) => {
      state.isLoading = false;
      state.dashboardDetailsInventoryResponse = action.payload;
    },
    setDashboardDetailsEvaluationData: (state, action) => {
      state.isLoading = false;
      state.dashboardDetailsEvaluation = action.payload;
    },
    setDashboardDetailsEvaluationConfirmData: (state, action) => {
      state.isLoading = false;
      state.dashboardDetailsEvaluationconfirm = action.payload;
    },
    setDashboardDetailsEvaluationResponseData: (state, action) => {
      state.isLoading = false;
      state.dashboardDetailsEvaluationResponse = action.payload;
    },
    setDashboardDetailsFollowupData: (state, action) => {
      state.isLoading = false;
      state.dashboardDetailsFollowup = action.payload;
    },
    setDashboardDetailsFollowupResponseData: (state, action) => {
      state.isLoading = false;
      state.dashboardDetailsFollowupResponse = action.payload;
    },
    setDashboardDetailsError: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload;
    },
    clearDashboardDetailsError: (state) => {
      state.isLoading = false;
      state.errors = [];
    },
  },
});

export const {
  clearDashboardDetailsData,
  setDashboardDetailsLoading,
  setDashboardDetailsInventoryData,
  setDashboardDetailsInventoryResponseData,
  setDashboardDetailsEvaluationData,
  setDashboardDetailsEvaluationConfirmData,
  setDashboardDetailsEvaluationResponseData,
  setDashboardDetailsFollowupData,
  setDashboardDetailsFollowupResponseData,
  setDashboardDetailsError,
  clearDashboardDetailsError,
} = slice.actions;

export default slice.reducer;
