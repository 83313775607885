import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";

import { PersistGate } from "redux-persist/integration/react";
import reportWebVitals from "./reportWebVitals";

import store, { persistor } from "../src/redux/store";
import App from "./app/App";
import "./index.css";
import "./assets/styles/css/bootstrap.min.css";
import "./assets/styles/vendor/fontawesome/css/all.min.css";
import "./assets/styles/css/style.css";
import "./assets/styles/css/tmc-style.css";
import "./assets/styles/css/participant-style.css"
import i18n from "./helper/translator";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <I18nextProvider i18n={i18n}>
    {/* <React.StrictMode> */}
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </PersistGate>
      </Provider>
      {/* </React.StrictMode> */}
  </I18nextProvider>
);

reportWebVitals();
