import React from "react";
import AppLayout from "../layouts/appLayout";


const WorkshopList = React.lazy(() => import("../pages/workshop/workshops"));
const Participants = React.lazy(() => import("../pages/participants/participants"));
const ParticipantsDetails = React.lazy(() => import("../pages/participants/participantsDetails"));
const WorkshopDetails = React.lazy(() => import("../pages/workshopDetails/workshopDetail"));
const InventoryResponse = React.lazy(() => import("../pages/inventoryResponse/inventoryResponse"));
const FollowupResponse = React.lazy(() => import("../pages/followupResponse/followupResponse"));
const WorkshopAdmins = React.lazy(() => import("../pages/authorization/workshopAdmins"));
/**
 * Array of route objects defining the routes for the application pages.
 */
export const appRoutes = [
  {
    path: "", // The base path for the app routes
    element: <AppLayout />, // Renders the AppLayout component for all child routes
    children: [
      {
        path: "companies/:company_id/workshops",
        element: <WorkshopList />, // Renders the WorkshopList component for the /workshops path
      },
      {
        path: "/companies/:company_id/participants",
        element: <Participants/>, // Renders the partcipantList component for the /companies/:company_id/participants path
      },
      {
        path: "/companies/:company_id/participants/:participant_id",
        element: <ParticipantsDetails/>, // Renders the partcipant deatils component for the /companies/:company_id/participants path
      },
      {
        path:"/companies/:company_id/workshops/:workshop_id",
        element:<WorkshopDetails/>
      }, 
      {
        path:"/companies/:company_id/workshops/:workshop_id/inventories/:inventory_id/responses",
        element:<InventoryResponse/>
      }, 
      {
        path:"/companies/:company_id/workshops/:workshop_id/followups/:followup_id/responses",
        element:<FollowupResponse/>
      }, 
      {
        path:"/companies/:company_id/users",
        element:<WorkshopAdmins/>
      }, 
    ],
  },
];
