import React from "react";
import CompanyLayout from "../layouts/companyLayout";

const CompaniesList = React.lazy(() => import("../pages/company/companies"));

/**
 * Array of route objects defining the routes for the company-related pages.
 */
export const companyRoutes = [
  {
    path: "", // The base path for the company routes
    element: <CompanyLayout />, // Renders the CompanyLayout component for all child routes
    children: [
      {
        path: "/companies",
        element: <CompaniesList />, // Renders the CompaniesList component for the /companies path
      },
    ],
  },
];
