import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  participantData: {},
  errors: [],
};

export const slice = createSlice({
  name: "participant",
  initialState,
  reducers: {
    clearParticipantData: () => initialState,
    setParticipantLoading: (state, action) => {
      state.isLoading = true;
    },
    setParticipantData: (state, action) => {
      state.isLoading = false;
      state.participantData = action.payload;
    },
    setParticipantError: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload;
    },
    clearParticipantError: (state) => {
      state.isLoading = false;
      state.errors = [];
    },
  },
});

export const {
  clearParticipantData,
  setParticipantLoading,
  setParticipantData,
  setParticipantError,
  clearParticipantError,
} = slice.actions;

export default slice.reducer;
