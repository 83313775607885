import React from "react";
import { Outlet,Navigate} from "react-router-dom";

function AuthLayout() {
    const isLogin = localStorage.getItem("admin_token") || null;
    const company_id =localStorage.getItem("company_id")||null
    const authority =localStorage.getItem("authority")||null
    
    return (
    <>
        {!isLogin?(<div className="text-center three-login-bg login_center form-padding-reg min-vh-100" >
        <Outlet/>
</div>):(authority==1?(<Navigate to={"/companies"} replace />):(<Navigate to={"/companies/" + company_id + "/workshops"} replace />))
        }
    </>

    )
}

export default AuthLayout