import React from "react";
import { Outlet, Navigate } from "react-router-dom";

function ParticipantchangepasswordLayout() {
    
    const isLogin = localStorage.getItem("participant_token") || null;

    return (
        <>
            {isLogin ? (<div className="text-center three-login-bg login_center form-padding-reg min-vh-100" >
                <Outlet />
            </div>) : (<Navigate to="/participants/login" replace />)
            }
        </>

    )
}

export default ParticipantchangepasswordLayout