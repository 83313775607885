import i18n from "i18next";
import enTranslation from "../utils/constant/en.json";
import jaTranslation from "../utils/constant/ja.json";
/*transilation setting */
i18n.init({
  resources: {
    en: {
      translation: enTranslation,
    },
    ja: {
      translation: jaTranslation,
    },
  },
  fallbackLng: "ja",
  Ing:'ja',
  debug: true,
  interpolation: {
    escapeValue: false,
  },
});
export default i18n