import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  workshopAdminData: {},
  errors: [],
};

export const slice = createSlice({
  name: "workshopAdmin",
  initialState,
  reducers: {
    clearWorkshopAdminData: () => initialState,
    setWorkshopAdminLoading: (state, action) => {
      state.isLoading = true;
    },
    setWorkshopAdminData: (state, action) => {
      state.isLoading = false;
      state.workshopAdminData = action.payload;
    },
    setWorkshopAdminDataError: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload;
    },
    clearWorkshopAdminDataError: (state) => {
      state.isLoading = false;
      state.errors = [];
    },
  },
});

export const {
  clearWorkshopAdminData,
  setWorkshopAdminLoading,
  setWorkshopAdminData,
  setWorkshopAdminDataError,
  clearWorkshopAdminDataError,
} = slice.actions;

export default slice.reducer;
