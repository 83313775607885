import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  workshopsData: {},
  errors: {},
};

export const slice = createSlice({
  name: "workshop",
  initialState,
  reducers: {
    clearworkshopsData: () => initialState,
    setworkshopsLoading: (state, action) => {
      state.isLoading = true;
    },
    setworkshopsData: (state, action) => {
      state.isLoading = false;
      state.workshopsData = action.payload;
    },
    setworkshopsError: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload;
    },
    clearworkshopsError: (state) => {
      state.isLoading = false;
      state.errors = {};
    },
  },
});

export const {
  clearworkshopsData,
  setworkshopsLoading,
  setworkshopsData,
  setworkshopsError,
  clearworkshopsError,
} = slice.actions;

export default slice.reducer;
