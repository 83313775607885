import React from 'react'
const ParticipantFooter = () => {
    return (
        
            <footer className="mt-auto ">
                <div className="container foot_sep p-0 py-3">
                    <div className="d-flex justify-content-between flex-md-row flex-column ">
                        <div className="text-md-start text-sm-center text-center content_text">© COACH A Co., Ltd. All Rights Reserved.</div>
                        <div className="text-md-end text-sm-center py-md-0 py-sm-3 text-center privacy_link">
                            <a href="https://www.coacha.com/etc/privacy.html" target="_blank" rel="noopener noreferrer" className="top_links">プライバシーポリシー</a>
                        </div>
                    </div>
                </div>
            </footer>
        
    )
}

export default ParticipantFooter
