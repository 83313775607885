import React from 'react';
import { Navigate } from "react-router-dom";
import ParticipantauthLayout from "../layouts/participantAuthLayout";
import ParticipantListLayout from "../layouts/participantListLayout";
import ParticipantDetailsLayout from "../layouts/participantDetailsLayout";
import ParticipantchangepasswordLayout from "../layouts/participantchangepasswordLayout"


const ParticipantLogin = React.lazy(() =>
  import("../pages/authentication/participantLogin/participantLogin"));
const ParticipantForgotPassword = React.lazy(() =>
  import("../pages/authentication/participantForgotPassword/participantForgotPassword")
);
const ParticipantResetPassword = React.lazy(() =>
  import("../pages/authentication/participantResetPassword/participantResetPassword")
);
const ParticipantResetPasswordSuccess = React.lazy(() =>
  import("../pages/authentication/participantResetPassword/participantResetPasswordSuccess")
);
const ParticipantRegisterPassword = React.lazy(() =>
  import("../pages/authentication/participantRegisterPassword/participantSetPassword")
);
const ParticipantRegisterPasswordSuccess = React.lazy(() =>
  import("../pages/authentication/participantRegisterPassword/participantSetPasswordSuccess")
);
const ParticipantHome = React.lazy(() =>
  import("../pages/participants/home/participantHome")
);
const ParticipantInventories = React.lazy(() =>
  import("../pages/participants/inventory/participantInventories")
);
const ParticipantInventoryQuestions = React.lazy(() =>
  import("../pages/participants/inventory/participantInventoryDetails")
);
const ParticipantInventoryAnswers = React.lazy(() =>
  import("../pages/participants/inventory/participantInventoryResponse")
);
const ParticipantEvaluations = React.lazy(() =>
  import("../pages/participants/evaluation/participantEvaluations")
);
const ParticipantChangePassword = React.lazy(() =>
  import("../pages/participants/changepassword/changePassword")
);
const ParticipantChangePasswordSuccess = React.lazy(() =>
  import("../pages/participants/changepassword/changePasswordSuccess"))

const ParticipantEvaluationQuestions = React.lazy(() =>
  import("../pages/participants/evaluation/participantEvaluationDetails")
);
const ParticipantEvaluationConfirm = React.lazy(() =>
  import("../pages/participants/evaluation/participantEvaluationConfirm")
);
const ParticipantEvaluationAnswers = React.lazy(() =>
  import("../pages/participants/evaluation/participantEvaluationResponse")
);

const ParticipantFollowups = React.lazy(() =>
  import("../pages/participants/followup/participantFollowups")
);
const ParticipantFollowupQuestions = React.lazy(() =>
  import("../pages/participants/followup/participantFollowupDetails")
);

const ParticipantFollowupAnswers = React.lazy(() =>
  import("../pages/participants/followup/participantFollowupResponse")
);

const ContentPage = React.lazy(() =>
  import("../pages/contentPage/contentPage")
);

/**
 * Array of route objects defining the routes for the authentication-related pages.
 */
export const participantRoutes = [
  {
    path: "", // The base path for the authentication routes
    element: <ParticipantauthLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="/participants/login" replace />  // Redirects to /login or /home based on the login flag
      },
      {
        path: "/participants/login",
        element: <ParticipantLogin />,
      },
      {
        path: "/participants/forgot-password",
        element: <ParticipantForgotPassword />,
      },
      {
        path: "/participants/reset-password/",
        element: <ParticipantResetPassword />,
      },
      {
        path: "/participants/reset-password/success",
        element: <ParticipantResetPasswordSuccess />,
      },
      {
        path: "/participants/set-password/",
        element: <ParticipantRegisterPassword />,
      },
      {
        path: "/participants/set-password/success",
        element: <ParticipantRegisterPasswordSuccess />,
      },

    ],
  },
  {
    path: "", // The base path for the authentication routes
    element: <ParticipantchangepasswordLayout />, // Renders the ParticipantLayout component only if login is true
    children: [
      {
        path: "/participants/change-password/",
        element: <ParticipantChangePassword />,
      },
      {
        path: "/participants/change-password/success",
        element: <ParticipantChangePasswordSuccess />,
      },
    ]

  },
  {
    path: "", // The base path for the authentication routes
    element: <ParticipantListLayout />, // Renders the ParticipantLayout component only if login is true
    children: [
      {
        path: "/participants/home",
        element: <ParticipantHome />,
      },
      {
        path: "/participants/inventories",
        element: <ParticipantInventories />,
      },
      {
        path: "/participants/evaluations",
        element: <ParticipantEvaluations />,
      },
      {
        path: "/participants/followups",
        element: <ParticipantFollowups />,
      },
      {
        path: "/participants/content-download",
        element: <ContentPage />, 
      },
    ],
  },
  {
    path: "", // The base path for the authentication routes
    element: <ParticipantDetailsLayout />, // Renders the ParticipantDetailsLayout component only if login is true
    children: [
      {
        path: "/participants/inventories/:inventory_id",
        element: <ParticipantInventoryQuestions />,
      },
      {
        path: "/participants/inventories/:inventory_id/results",
        element: <ParticipantInventoryAnswers />,
      },
      {
        path: "/participants/evaluations/:workshop_id",
        element: <ParticipantEvaluationQuestions />,
      },
      {
        path: "/participants/evaluations/:workshop_id/confirm",
        element: < ParticipantEvaluationConfirm />,
      },
      {
        path: "/participants/evaluations/:workshop_id/results",
        element: <ParticipantEvaluationAnswers />,
      },
      {
        path: "/participants/followups/:followup_id",
        element: <ParticipantFollowupQuestions />,
      },
      {
        path: "/participants/followups/:followup_id/results",
        element: <ParticipantFollowupAnswers />,
      },
    ],
  },
];
