import React, { Suspense } from "react";
import { ErrorBoundary } from 'react-error-boundary';
import { useRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import WhiteSpinner from "../components/common/spinner/WhiteSpinner";
import { routes } from "../routes/globalRouter";
import ScrollToTop from "../helper/scrollTop";
import ErrorFallback from "../components/errors/errorFallback"
import "react-toastify/dist/ReactToastify.css";

/**
 * The root component of the application.
 * It renders the appropriate component based on the current route using React Router.
 * It also includes a scroll-to-top functionality and a fallback UI while the route is loading asynchronously.
 */
function App() {
  const route = useRoutes(routes);

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ScrollToTop /> {/* Scrolls to the top of the page */}
      <Suspense fallback={<div><WhiteSpinner/></div>}>{route}</Suspense>
      <ScrollToTop /> {/* Scrolls to the top of the page */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      </ErrorBoundary>
    </>
  );
}

export default App;
