import { createSelector } from "@reduxjs/toolkit";

const ParticipantLoginSelector = (state) => state.ParticipantLoginReducer;

export const ParticipantLoginDataSelector = createSelector(ParticipantLoginSelector, (state) => {
  return state?.loginData || {};
});

export const ParticipantLoginErrorSelector = createSelector(ParticipantLoginSelector, (state) => {
  return state?.errors?.details ? (state?.errors?.details || []) : (state?.errors || {});
});

export const TokenRoleDataSelector = createSelector(ParticipantLoginSelector, (state) => {
  return {
    ...state?.loginData,
    ...state?.loginData.user

  } || {};
});
export const ParticipantTokenRoleDataSelector = createSelector(ParticipantLoginSelector, (state) => {
  return {
    ...state?.loginData,
    ...state?.loginData.user

  } || {};
});

export const CompanyIdSelector = createSelector(ParticipantLoginSelector, (state) => {
  return state?.company;
});

export const WorkshopIdSelector = createSelector(ParticipantLoginSelector, (state) => {
  return state?.workshop;
});
