import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  participantDetails: {},
  errors: [],
};

export const slice = createSlice({
  name: "participantDetails",
  initialState,
  reducers: {
    clearParticipantDetailsData: () => initialState,
    setParticipantDetailsLoading: (state, action) => {
      state.isLoading = true;
    },
    setParticipantDetailsData: (state, action) => {
      state.isLoading = false;
      state.participantDetails = action.payload;
    },
    setParticipantDetailsError: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload;
    },
    clearParticipantDetailsError: (state) => {
      state.isLoading = false;
      state.errors = [];
    },
  },
});

export const {
  clearParticipantDetailsData,
  setParticipantDetailsLoading,
  setParticipantDetailsData,
  setParticipantDetailsError,
  clearParticipantDetailsError,
} = slice.actions;

export default slice.reducer;
