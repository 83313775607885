import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  loginData: {},
  errors:{},
  
};

export const slice = createSlice({
  name: "participantLogin",
  initialState,
  reducers: {
    clearparticipantLoginData: () => initialState,
    setParticipantLoginLoading:(state, action) => {
      state.isLoading = true;
    },
    setParticipantLoginData: (state, action) => {
      state.isLoading = false;
      state.loginData = action.payload;
    },
    setParticipantLoginError: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload;
    },
    clearParticipantLoginError: (state) => {
      state.isLoading = false;
      state.errors = {};
    },
    setToken: (state, action) => {
      state.isLoading = false;
      state.loginData.access_token = action.payload?.access_token;
      state.loginData.expires_in = action.payload?.expires_in;
      state.loginData.token_type = action.payload?.token_type;
    },
  },
});

export const { clearparticipantLoginData, setParticipantLoginLoading,setParticipantLoginData,setParticipantLoginError,clearParticipantLoginError,setToken} =slice.actions;

export default slice.reducer;
