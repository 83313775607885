import { configureStore } from '@reduxjs/toolkit'
import rootReducer from '../store/rootReducer'
//persist middleware
import { persistStore,persistReducer } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

//import {postApi} from '../service/api'

const persistConfig={
  key:"persisit-key"|| "root",
  storage,
  stateReconciler: autoMergeLevel2,
}
const persistedReducer=persistReducer(persistConfig,rootReducer)

export const store = configureStore({
 // reducer:{[postApi.reducerPath]:postApi.reducer},
  reducer: persistedReducer,
  middleware: [thunkMiddleware],
  

})
const persistor = persistStore(store);
export { persistor };
export default store;