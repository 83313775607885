import React from 'react'
import spinnerstyle from "./Spinner.module.css"



const WhiteSpinner = () => {
  return (
    <div className={spinnerstyle.loading}></div>
  )
}

export default WhiteSpinner
