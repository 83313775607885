
import React from "react";
import Sidebar from "../components/common/sideBar";
import { useSelector } from 'react-redux'
import { Outlet,Navigate } from "react-router-dom";
import {TokenRoleDataSelector} from '../redux/selectors/loginSelector'

const AppLayout = (props) => {
   
    const tokenRoleData=useSelector(TokenRoleDataSelector)
    const isLogin = localStorage.getItem("admin_token") || null;
    return (<>
      {isLogin?(
        <Sidebar>
           <Outlet />
        </Sidebar>  
      ):(
        <Navigate to='/login' />
      )
    } 
    </>

    )
};

export default AppLayout;