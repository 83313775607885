import { createSelector } from "@reduxjs/toolkit";

const LoginSelector = (state) => state.Loginreducer;

export const LoginDataSelector = createSelector(LoginSelector, (state) => {
  return state?.loginData || {};
});

export const LoginErrorSelector = createSelector(LoginSelector, (state) => {
  return state?.errors?.details?(state?.errors?.details|| []):(state?.errors||{}) ;
});

export const TokenRoleDataSelector = createSelector(LoginSelector, (state) => {
  return {
    ...state?.loginData,
    ...state?.loginData.user

  }  || {};
});

export const CompanyIdSelector = createSelector(LoginSelector, (state) => {
  return state?.company;
});

export const WorkshopIdSelector = createSelector(LoginSelector, (state) => {
  return state?.workshop;
});
