import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  registerData: {},
  errors:{}
};

export const slice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    clearRegisterData: () => initialState,
    setresgisterLoading:(state, action) => {
      state.isLoading = action.payload;
    },
    setRegisterData: (state, action) => {
      state.isLoading = false;
      state.registerData = action.payload;
    },
    setRegisterError: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload;
    },
    clearRegisterError: (state) => {
      state.isLoading = false;
      state.errors = {};
    },
  },
});

export const { clearRegisterData, setRegisterData,setRegisterError,clearRegisterError,setresgisterLoading} =slice.actions;

export default slice.reducer;
