import { combineReducers } from "@reduxjs/toolkit";
import Registerreducer from "../slice/registerSlice";
import Loginreducer from "../slice/loginSlice";
import ParticipantLoginReducer from "../slice/participantLoginSlice";
import CompanyReducer from "../slice/companySlice";
import ParticipantReducer from "../slice/participantSlice";
import WorkshopReducer from "../slice/workshopSlice";
import participantDetailsReducer from "../slice/participantDetailsSlice";
import WorkshopDetails from "../slice/workshopDetailSlice";
import WorkshopAdmin from "../slice/workshopAdminSlice";
import ParticipantDashboard from "../slice/participantDashboardSlice";
import DashboardDetails from "../slice/dashboardDetailsSlice";

const rootReducer = combineReducers({
  RegisterReducer: Registerreducer,
  Loginreducer: Loginreducer,
  ParticipantLoginReducer:ParticipantLoginReducer,
  CompanyReducer: CompanyReducer,
  ParticipantReducer: ParticipantReducer,
  WorkshopReducer: WorkshopReducer,
  participantDetailsReducer: participantDetailsReducer,
  WorkshopDetailsReducer: WorkshopDetails,
  WorkshopAdminReducer: WorkshopAdmin,
  ParticipantDashboardReducer:ParticipantDashboard,
  DashboardDetailsReducer:DashboardDetails,
});
export default rootReducer;
